<!--秒杀列表-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-wrap">
                <label>场景：</label>
                <el-select v-model="select.status" placeholder="选择秒杀状态" filterable clearable class="selectWidth" @change="curr=1;getList()">
                    <el-option v-for="(item,index) in selectData" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
                <el-date-picker v-model="select.time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="秒杀时间" end-placeholder="筛选" value-format="yyyy-MM-dd" @change="curr=1;getList()">
                </el-date-picker>
                <el-input placeholder="搜索商品名称" v-model="select.search" size="small" style="width:300px;" @change="curr=1;getList()" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff" @click="curr=1;getList()"></el-button>
                </el-input>
            </div>
        </div>
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="id" label="商品ID" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column prop="name" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column label="商品图片" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image class="tableImage" :src="domain + scope.row.image" fit="cover" :preview-src-list="previewList"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="cate_name" label="商品分类" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="seckill_price" label="秒杀价" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sales" label="销量" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="price" label="秒杀状态" width="150" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.state == 0" style="color:#67C23A">未开始</span>
                        <span v-if="scope.row.state == 1" style="color:#7B84FF">进行中</span>
                        <span v-if="scope.row.state == 2" style="color:#F56C6C">已结束</span>
                    </template>
                </el-table-column>
                <el-table-column prop="start_time" label="开始时间" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="end_time" label="结束时间" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="180">
                    <template slot-scope="scope">
                        <el-button type="success" plain size="mini" @click="showDetail(scope.row)">查看</el-button>
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {shopSkillListApi,shopSkillDeleteApi}  from '@/api/store.js';
    export default {
        data() {
            return {
                select:{},
                selectData:[{
                    id:1,
                    name:'进行中'
                },{
                    id:0,
                    name:'未开始'
                },{
                    id:2,
                    name:'已结束'
                }], //下拉选择列表
                list:[], //商品列表
                previewList:[], //图片预览列表

                title:'添加商品',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
            }
        },
        mounted() {
            this.init();

        },
        methods: {
            init: function(){
                this.getList();
            },
            //商品列表
            getList: function(){
                shopSkillListApi({
                    row:this.row,
                    curr:this.curr,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                    response.list.forEach(item => {
                        this.previewList.push(this.domain + item.image);
                    });
                })
            },
            //进入商品详情
            showDetail: function(param = {}){
                this.title = '商品详情';
                //跳转至添加or编辑页面
                this.$router.push({path:'/shopAdd',query:{id:param.product_id,title:this.title,isShow:JSON.stringify(true)}});
            },

            //删除秒杀数据
            deletes:function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    shopSkillDeleteApi({
                        id:id
                    }).then(()=>{
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '已删除!'
                        });
                    })
                }).catch(()=>{})
            },
            //设置上架
            setupSwitch: function(row) {
                console.log(row)
            },
            //批量设置上架
            handleSelectionChange: function(val) {
                console.log(val)
            },

            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>